@import url("./overview.scss");
@import url("./settings.scss");
@import url("./layout.scss");

html,
body {
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;

  nextjs-portal {
    display: none;
  }
}

.maxW {
  width: full;
  margin: 0 auto;
  max-width: 1256px;
}

::-webkit-scrollbar {
  height: 0px;
  width: 8px;
  border: 1px solid #fff;
  display: none;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #EEEEEE;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #B0B0B0;
}


.css-5gngp[aria-checked='true'],
.css-5gngp[data-checked] {
  background-color: #106CFF !important;
  border-color: #106CFF !important;
}

.header {
  .menu-right-header {

    .gas-fee-item,
    .fiat-item,
    .helps-item,
    .toggle-balance-big,
    .more-actions-item {
      svg {
        path {
          fill: #106cff !important;
        }
      }

      .border-box {
        border: 1px #106cff solid;
      }
    }

    .currency-item {
      .border-box {
        border: 1px #106cff solid;
      }
    }
  }
}


.text-Token {
  animation: fadeIn 1s;
}

.text-AMM {
  animation: fadeInAMM 1s;
}

.text-Blockchain {
  animation: fadeInBlockchain 1s;
}

@keyframes fadeInAMM {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInBlockchain {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.icon {
  position: absolute;
  left: 0;
  offset-distance: 0;
  opacity: 0;
  border-radius: 50%;
  animation: run 10s linear infinite;
}

.icon-arb {
  top: 90px;
  offset-path: path("M26.2773438,65.3125 C87.8220281,64.8771564 153.929385,68.7871406 213.859375,53.453125 C237.64527,47.3671355 260.486231,37.8691761 284.34375,32.0703125 C295.419973,29.3781002 306.949583,29.0625601 318.304687,28.0664063 C366.147245,23.8693029 414.087633,20.8263917 461.917969,16.4921875 C502.60711,12.8050913 543.146953,7.56109266 583.847656,4.00390625 C640.008507,-0.904476121 673.880396,2.05721656 735.265625,1.21875 C767.426971,0.779455177 792.211623,-1.59597934 822.734375,8.99609375");
}

.icon-eth {
  top: 90px;
  offset-path: path("M0.508122866,32.4010741 C40.8983851,33.0259844 -3.67597038,31.5222237 78.8700816,43.7048387 C112.938293,48.7328187 152.829441,53.0969032 186.737637,46.3392889 C215.070466,40.6927979 237.500374,24.2866129 261.981013,11.441527 C278.049995,3.0100704 298.378147,1.73622861 316.245223,1.19386365 C340.136329,0.468635899 364.055996,0.933881034 387.959675,1.14357312 C391.847733,1.17768063 395.781023,1.23303457 399.591731,1.92501064 C429.895866,7.42785533 451.716964,30.0119329 481.340074,35.9755705 C529.110604,45.5925939 566.591574,-10.1443337 617.508123,9.42603718")
}

.icon-bsc {
  top: 90px;
  offset-path: path("M0.9765625,197.265625 C71.4826965,193.777799 -27.5692997,200.243739 66.3671875,186.941406 C126.014304,178.494786 186.075565,173.205265 245.761719,165.039062 C278.720318,160.529698 263.692127,156.586159 296.730469,155.367188 C415.134929,150.998575 364.559141,156.265447 478.640625,157.667969 C536.332881,158.37724 594.036202,157.147475 651.730469,157.667969 C682.639919,157.946821 713.838466,167.919964 744.621094,164.5 C768.997171,161.791807 786.510077,149.146002 809.714844,139.585938 C831.95067,130.425064 853.507455,129.778069 877.226563,129.246094")
}

.icon-fantom {
  top: 90px;
  offset-path: path("M50.2226562,316.957031 C95.1432318,317.019531 140.071091,316.332763 184.984375,317.144531 C267.031433,318.62746 348.067014,326.75703 429.582031,312.179688 C467.115015,305.467659 467.725938,294.3423 499.800781,280.503906 C533.501469,265.964056 570.900881,258.170878 607.5625,257.195312 C705.995877,254.575999 709.956264,264.812862 823.675781,288.089844 C856.151776,294.737279 890.031345,290.344399 922.949219,294.277344")
}

.icon-sol {
  top: 90px;
  offset-path: path("M80.5859375,483.5 C123.174734,486.374004 176.801886,494.931225 219.90625,486.617188 C224.13604,485.801339 228.337846,484.726541 232.378906,483.234375 C259.810568,473.105202 286.836792,461.899688 314.269531,451.773438 C331.370095,445.461104 425.34648,406.186381 472.507812,398.625 C480.320264,397.372429 488.272131,397.035739 496.183594,396.925781 C540.657455,396.307659 585.210439,398.960735 629.617188,396.441406 C700.581122,392.415408 768.248371,374.472331 838.640625,369.320312")
}

.icon-terra {
  top: 90px;
  offset-path: path("M167.148438,553.570312 C303.33724,553.570312 439.526427,553.245678 575.714844,553.570312 C579.923469,553.580345 584.162442,553.808208 588.300781,554.574219 C610.546737,558.691968 632.518179,564.226308 654.789063,568.207031 C687.235413,574.006529 720.87667,571.105109 753.371094,573.546875 C777.645149,575.370928 816.805531,584.521367 843.277344,582.539062 C847.9443,582.189584 852.13949,579.359766 856.171875,576.984375 C866.537733,570.878072 876.07662,563.44915 886.339844,557.171875 C917.457424,538.13949 947.176623,524.760012 981.484375,513.003906");
}

.icon-heco {
  top: 90px;
  offset-path: path("M0.508122866,32.4010741 C40.8983851,33.0259844 -3.67597038,31.5222237 78.8700816,43.7048387 C112.938293,48.7328187 152.829441,53.0969032 186.737637,46.3392889 C215.070466,40.6927979 237.500374,24.2866129 261.981013,11.441527 C278.049995,3.0100704 298.378147,1.73622861 316.245223,1.19386365 C340.136329,0.468635899 364.055996,0.933881034 387.959675,1.14357312 C391.847733,1.17768063 395.781023,1.23303457 399.591731,1.92501064 C429.895866,7.42785533 451.716964,30.0119329 481.340074,35.9755705 C529.110604,45.5925939 566.591574,-10.1443337 617.508123,9.42603718");
}

.icon-ok {
  top: 90px;
  offset-path: path("M389.164062,491.773438 C518.47801,491.773438 454.895461,492.869407 674.851562,479.679688 C722.128471,476.844717 769.248347,471.056005 816.59375,469.808594 C909.619647,467.357636 1002.90047,474.580388 1095.76563,468.589844 C1117.28145,467.201901 1136.27793,453.692058 1157.03125,447.847656 C1165.08665,445.579151 1173.60417,445.50651 1181.89062,444.335938");
}

.icon-boba {
  top: 90px;
  offset-path: path("M80.5859375,483.5 C123.174734,486.374004 176.801886,494.931225 219.90625,486.617188 C224.13604,485.801339 228.337846,484.726541 232.378906,483.234375 C259.810568,473.105202 286.836792,461.899688 314.269531,451.773438 C331.370095,445.461104 425.34648,406.186381 472.507812,398.625 C480.320264,397.372429 488.272131,397.035739 496.183594,396.925781 C540.657455,396.307659 585.210439,398.960735 629.617188,396.441406 C700.581122,392.415408 768.248371,374.472331 838.640625,369.320312");
}

.icon-dot {
  top: 90px;
  offset-path: path("M0.508122866,32.4010741 C40.8983851,33.0259844 -3.67597038,31.5222237 78.8700816,43.7048387 C112.938293,48.7328187 152.829441,53.0969032 186.737637,46.3392889 C215.070466,40.6927979 237.500374,24.2866129 261.981013,11.441527 C278.049995,3.0100704 298.378147,1.73622861 316.245223,1.19386365 C340.136329,0.468635899 364.055996,0.933881034 387.959675,1.14357312 C391.847733,1.17768063 395.781023,1.23303457 399.591731,1.92501064 C429.895866,7.42785533 451.716964,30.0119329 481.340074,35.9755705 C529.110604,45.5925939 566.591574,-10.1443337 617.508123,9.42603718");
}

.icon-moonriver {
  top: 90px;
  offset-path: path("M80.5859375,483.5 C123.174734,486.374004 176.801886,494.931225 219.90625,486.617188 C224.13604,485.801339 228.337846,484.726541 232.378906,483.234375 C259.810568,473.105202 286.836792,461.899688 314.269531,451.773438 C331.370095,445.461104 425.34648,406.186381 472.507812,398.625 C480.320264,397.372429 488.272131,397.035739 496.183594,396.925781 C540.657455,396.307659 585.210439,398.960735 629.617188,396.441406 C700.581122,392.415408 768.248371,374.472331 838.640625,369.320312");
}

.icon-neo {
  top: 90px;
  offset-path: path("M167.148438,553.570312 C303.33724,553.570312 439.526427,553.245678 575.714844,553.570312 C579.923469,553.580345 584.162442,553.808208 588.300781,554.574219 C610.546737,558.691968 632.518179,564.226308 654.789063,568.207031 C687.235413,574.006529 720.87667,571.105109 753.371094,573.546875 C777.645149,575.370928 816.805531,584.521367 843.277344,582.539062 C847.9443,582.189584 852.13949,579.359766 856.171875,576.984375 C866.537733,570.878072 876.07662,563.44915 886.339844,557.171875 C917.457424,538.13949 947.176623,524.760012 981.484375,513.003906");
}

@keyframes run {
  0% {
    offset-distance: 0;
    opacity: 0;
  }

  5% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    offset-distance: 100%;
  }
}

@media only screen and (max-width: 1200px) {

  ::-webkit-scrollbar,
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    display: none;
  }
}



.slick-dots {
  .slick-active {
    .button__bar {
      background-color: #2669f5 !important;
    }
  }
}

.App {
  text-align: center;
}

.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  font-size: 40px;
  text-align: center;
}

.scroller>span {
  position: absolute;
  color: #fff;
  top: 0;
  animation: slide 6s infinite;
  font-weight: bold;
}

@keyframes slide {
  0% {
    top: 0;
  }

  25% {
    top: -1.2em;
  }

  50% {
    top: -2.4em;
  }

  75% {
    top: -3.6em;
  }
}

.border-image {
  border-image: linear-gradient(180deg, hsla(0, 0%, 78.4%, 0), hsla(0, 0%, 100%, .09), hsla(0, 0%, 59.2%, 0)) 1 1;
}


.chakra-popover__popper {
  z-index: 999 !important;
}

@media only screen and (max-width: 500px) {
  .sub-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// .highcharts-background {
//   fill: #09162e !important;
// }

.highcharts-exporting-group {
  display: none !important;
}


.input-range__label {
  display: none;
}

.input-range__slider {
  height: 14px;
  width: 14px;
  background: #425160;
  border: 1px solid #425160;
}

.input-range__track {
  background: #bcbec0;
  height: 4px;
}

.input-range__track--active {
  background: #425160;
}

.input-range__track,
.input-range__slider,
.input-range__slider-container {
  transition: none;
}

.divider-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  bottom: -16px;
}

.divider-container__item {
  height: 14px;
  border: 1px solid red;
}

.link {
  padding: 16px;
  width: 100%;
}

@media only screen and (max-width: 850px) {
  .link {
    padding: 16px;
    width: max-content;
  }
}

.blur {
  backdrop-filter: opacity(20%);
  background-color: #000;
}