.scrollBar {

  &::-webkit-scrollbar {
    width: 2px,
  }
  
  &::-webkit-scrollbar-track {
    width: 6px,
  }
  
  &::-webkit-scrollbar-thumb {
    border-radius: 24px,
  }
}
