.sidebar-box {
  .overview,
  .portfolio,
  .activity,
  .send,
  .setting,
  .your-wallet,
  .more-actions {
    svg {
      path {
        fill: #106cff !important;
      }
    }
  }
  .more-actions {
    p {
      color:  #106cff;
    }
  }
}

